import React, { useEffect } from "react";
import getTitleId from "../helpers/getTitleId";
import { schemaMarkupRendering } from "../helpers/schemaMarkupRendering";
import { LocalizedLink,Video } from "../commonComponents";
function VideoSection({ entity }) {
  const videoStandardEmbedCode = entity?.relationships?.fieldVideoRef?.fieldVideoTranscript?.value
  const videoUrl = entity?.relationships?.fieldVideoRef?.fieldMediaVideoEmbedField;
  return (
    <div className="my-6" id={getTitleId(entity.title)}>
      {schemaMarkupRendering(entity)}
      {!entity?.fieldHideTitle && (
        <h2 className="title title-3 has-text-centered">
          {entity?.title}
        </h2>
      )}
      <div className="card">
        <div className="card-content">
          <Video videoUrl={videoUrl} videoStandardEmbedCode={videoStandardEmbedCode}/>
        </div>
      </div>
      <div className="mt-5 has-text-centered">
        {!!entity.body && (
          <div dangerouslySetInnerHTML={{ __html: entity.body.value }} className="has-text-left dangerously-html-handle"/>
        )}
        {!!entity.fieldCtaUrl && (
          <LocalizedLink
            to={entity.fieldCtaUrl?.url || ""}
            className="is-primary mt-2"
            isButton
          >
            {entity.fieldCtaUrl?.title}
          </LocalizedLink>
        )}
      </div>
    </div>
  );
}
export default VideoSection;
